import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "@/auth/AuthenticationProvider";
import { uris } from "@/config/nav";
import { useNavigate } from "react-router-dom";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { Box, Stack, Typography } from "@mui/material";
import { StepperFlow } from "@/base/form/mui/StepperFlow";
import { useForm, useWatch } from "react-hook-form";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";
import { users, customers } from "@/queries";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  countryCodes,
  FormCountryCodePicker,
} from "@/base/form/mui/FormCountryCodePicker";

export const LinkSignupForm = () => {
  const [t] = useTranslation();
  const [auth, dispatch] = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const updateContext = useUnleashContext();
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (auth.authenticated) {
      console.log(
        "[LoginForm] redirect to home if user information is available",
      );
      users
        .me()
        .then((result) => {
          console.log(
            "[INFO] Retrieved user information, login user: " +
              JSON.stringify(result),
          );
          dispatch({ type: "LOGIN", payload: result });
          navigate(uris.home);
          updateContext({
            userId: result.id,
            hostName: window.location.hostname,
            account: result.currentTenant,
          });
        })
        .catch((error) => {
          console.log(
            "[WARN] Not able to access user information, log out",
            error,
          );
          dispatch({ type: "LOGOUT" });
        });
    }
  }, [auth]);

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      phoneCountry: "US",
      phoneNumber: "",
      tenantName: "",
      expectedNumberOfUsers: 0,
    },
  });
  const {
    formState: { isDirty, errors },
    handleSubmit,
    control,
  } = formData;
  const [
    firstName,
    lastName,
    email,
    role,
    phoneCountry,
    phoneNumber,
    tenantName,
    expectedNumberOfUsers,
  ] = useWatch({
    control,
    name: [
      "firstName",
      "lastName",
      "email",
      "role",
      "phoneCountry",
      "phoneNumber",
      "tenantName",
      "expectedNumberOfUsers",
    ],
  });

  const steps = useMemo(
    () => ["Personal information", "Account information", "Payment method"],
    [],
  );

  const createUser = useMutation(customers.add, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data, null, 2));
      toast.success("Profile created successfully!");
      navigate(uris.home);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating your profile");
    },
  });

  const triggerSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        console.log("Submitting data: " + JSON.stringify(data, null, 2));
        if (isDirty && activeStep === steps.length - 1) {
          await createUser.mutateAsync({
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            role: data.role,
            phone: data.phoneNumber?.length
              ? `+${
                  countryCodes.find(
                    (option) => option.code === data.phoneCountry,
                  )?.phone
                } ${data.phoneNumber}`
              : "",
            tenantName: data.tenantName,
            expectedNumberOfUsers: data.expectedNumberOfUsers,
          });
        } else {
          setActiveStep((a) => a + 1);
        }
      }),
    [handleSubmit, steps, isDirty, activeStep],
  );

  const submitButtonLabel = useMemo(() => {
    if (activeStep === 2) {
      return t("generic.button.create");
    }
    return t("generic.stepper.next");
  }, [
    activeStep,
    isDirty,
    firstName,
    lastName,
    email,
    role,
    phoneCountry,
    phoneNumber,
    tenantName,
    expectedNumberOfUsers,
  ]);

  const isStepDisabled = useMemo(() => {
    if (!firstName || !lastName || !email || errors.name || errors.email) {
      return [true, true, true];
    }
    return [false, activeStep === 0, activeStep < 2];
  }, [activeStep, firstName, lastName, email, errors.name, errors.email]);

  return (
    <Box>
      <FormDataProvider formKey="signupForm.fields" {...formData}>
        <form onSubmit={triggerSubmit}>
          <Stack spacing={3}>
            <StepperFlow
              steps={steps}
              activeStep={activeStep}
              isStepDisabled={isStepDisabled}
              setActiveStep={setActiveStep}
              formatLabel={(step) => step}
            />
            {activeStep === 0 && (
              <Stack spacing={2}>
                <FormTextField name="firstName" required />
                <FormTextField name="lastName" required />
                <FormTextField name="email" required />
                <FormTextField name="role" />
                <FormCountryCodePicker
                  name="phone"
                  countryName="phoneCountry"
                  phoneName="phoneNumber"
                />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack spacing={2}>
                <FormTextField name="tenantName" />
                <FormTextField type="number" name="expectedNumberOfUsers" />
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack spacing={2}>
                <Typography>Work in progress</Typography>
              </Stack>
            )}
            <StepperNavigator
              isDirty={isDirty}
              hideCancelButton
              showPreviousButton={activeStep > 0}
              setActiveStep={setActiveStep}
              submitButtonLabel={submitButtonLabel}
            />
          </Stack>
        </form>
      </FormDataProvider>
    </Box>
  );
};
