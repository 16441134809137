import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import React from "react";

export const BlockerAlert = ({ blocker }: any) => {
  return blocker.state === "blocked" ? (
    <Alert
      severity="warning"
      sx={{
        my: 2,
      }}
    >
      <AlertTitle>
        <Typography variant="h2">
          Unsaved changes. Are you sure you want to leave?
        </Typography>
      </AlertTitle>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => blocker.proceed()}>
          Proceed
        </Button>
        <Button variant="outlined" onClick={() => blocker.reset()}>
          Cancel
        </Button>
      </Stack>
    </Alert>
  ) : null;
};
